import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { dataFetch } from "../../../services/ApiEndPoint";
import LeadCard from "./LeadCard";

function LeadSearch() {
  const [leads, setLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLead, setSelectedLead] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [sortBy, setSortBy] = useState("firstname");
  const [pipelineStageFilter, setPipelineStageFilter] = useState("");
  const [leadOwnerFilter, setLeadOwnerFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { token } = useAuth();

  const fetchLeads = async () => {
    setLoadingData(true);
    try {
      let url = `lead/query?page=${page}&limit=${rowsPerPage}&sort=-pipelineComments.pipelineDate`;

      if (searchTerm) url += `&firstname=${searchTerm}`;
      if (pipelineStageFilter) url += `&pipelinestage=${pipelineStageFilter}`;
      if (leadOwnerFilter) url += `&leadowner=${leadOwnerFilter}`;
      if (dateFilter) url += `&date=${dateFilter}`;

      let response = await dataFetch(url, token, "GET");
      if (response.status === 200) {
        setLeads(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, [
    searchTerm,
    sortBy,
    pipelineStageFilter,
    leadOwnerFilter,
    dateFilter,
    page,
    rowsPerPage,
  ]);

  const handleLeadClick = async (leadId) => {
    setLoadingData(true);
    try {
      let url = `lead/${leadId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedLead(response.data.data);
      } else {
        console.error(`Error fetching Lead details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access - possibly due to an invalid or expired token."
        );
      } else {
        console.error("Error fetching Lead details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handleDateChange = (e) => {
    setDateFilter(e.target.value);
    setPage(1);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {selectedLead ? (
        <LeadCard lead={selectedLead} />
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Lead Dashboard
            </Typography>
          </Grid>

          {/* Search Bar, Sort, and Filter Controls */}
          <Grid
            container
            item
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Search leads"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Sort By</InputLabel>
                <Select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  label="Sort By"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="firstname">Full Name</MenuItem>
                  <MenuItem value="companyname">Company Name</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Pipeline Stage</InputLabel>
                <Select
                  value={pipelineStageFilter}
                  onChange={(e) => setPipelineStageFilter(e.target.value)}
                  label="Pipeline Stage"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="">All Stages</MenuItem>
                  <MenuItem value="prospect">Prospect</MenuItem>
                  <MenuItem value="qualified">Qualified</MenuItem>
                  <MenuItem value="proposal">Proposal</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Date</InputLabel>
                <TextField
                  type="date"
                  value={dateFilter}
                  onChange={handleDateChange}
                  InputLabelProps={{ shrink: true }}
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                />
              </FormControl>
            </Grid> */}
          </Grid>

          {/* Dynamic Search Results */}
          {searchTerm && !loadingData && (
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  maxHeight: 200,
                  overflow: "auto",
                  mt: 2,
                  padding: 2,
                  bgcolor: "#f1f1f1",
                  borderRadius: 2,
                }}
              >
                <List>
                  {leads.map((lead) => (
                    <ListItem
                      key={lead?._id}
                      sx={{
                        bgcolor: "#f4f4f4",
                        mb: 1,
                        borderRadius: 2,
                        boxShadow: 1,
                      }}
                      button
                      onClick={() => handleLeadClick(lead?._id)}
                    >
                      <ListItemText
                        primary={lead?.firstname}
                        secondary={`${lead?.email} - ${lead?.phonenumber}`}
                        primaryTypographyProps={{ fontWeight: "bold" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          )}

          {loadingData && (
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "100vh" }}
            >
              <CircularProgress />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default LeadSearch;
