import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import { getDateTimeSync, getTimeSync } from "../../../utils/DateTimeHelper"; // Assuming you already have this utility for formatting dates

function LeadProfile({ lead }) {
   const renderComments1 = () => {
      const comments = [];
  
      // Check if both pipeline stage and comment exist
      if (lead?.pipelinestage && lead?.pipelineComment) {
        comments.push(
          <Typography variant="body1" key="pipeline-comment" sx={{ color: 'blue', fontWeight: 'bold' }}>
            {`${lead.pipelinestage} - ${lead.pipelineComment}`}
          </Typography>
        );
      } else if (lead?.pipelineComment) {
        // If only pipeline comment exists
        comments.push(
          <Typography variant="body1" key="pipeline-comment" sx={{ color: 'blue', fontWeight: 'bold' }}>
            {`Pipeline Comment: ${lead.pipelineComment}`}
          </Typography>
        );
      } else if (lead?.additionalComments && lead.additionalComments.length > 0) {
        // If additional comments exist
        comments.push(
          <Typography variant="body1" key="additional-comments">
            {lead.additionalComments.join(', ')}
          </Typography>
        );
      } else {
        comments.push(<Typography variant="body1">No comments available</Typography>);
      }
  
      return comments;
    };
  const renderComments = () => {
    const comments = [];

    // Check if there are pipeline comments
    if (lead?.pipelineComments && lead.pipelineComments.length > 0) {
      comments.push(
        lead.pipelineComments.map((comment) => (
          <Box
            key={comment.pipelineDate}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              borderBottom: "1px solid #ddd",
              marginBottom: "10px",
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold", color: "blue" }}>
              {` ${comment.pipelineDate ? getDateTimeSync(comment?.pipelineDate) : "No Date"}-${comment.pipelineDate ? getTimeSync(comment?.pipelineDate) : "No Date"} | ${lead?.pipelinestage}`}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: "5px" }}>
              {comment.pipelineComment}
            </Typography>
          </Box>
        ))
      );
    } else {
      comments.push(<Typography variant="body1">No comments available</Typography>);
    }

    return comments;
  };

  const renderAssignedLeads = () => {
    if (!lead?.assignedLeads || lead.assignedLeads.length === 0) {
      return <Typography variant="body1">No assigned leads available</Typography>;
    }

    // Extract names and join them with a separator
    const assignedLeadNames = lead.assignedLeads.map((assignedLead) => assignedLead.name).join(" | ");

    return (
      <Typography variant="body1">
        {assignedLeadNames}
      </Typography>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={8} md={4}>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            padding: "16px",
            maxWidth: "100%",
            margin: "auto",
            mt: "10px",
          }}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  First Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{lead?.firstname}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  Full Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{lead?.fullname}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  Lead Owner:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{lead?.leadowner?.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  Date:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{getDateTimeSync(lead?.date)}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  Location:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{lead?.location}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  Pipeline Stage:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{lead?.pipelinestage}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  Company Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{lead?.companyname}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  Phone Number:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{lead?.phonenumber}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  Email:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{lead?.email}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  Timezone:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{lead?.timezone}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                  Assigned To:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {renderAssignedLeads()}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight="bold">
                Old Comments:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {renderComments1}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* Right Sidebar for Comments (Chat-like) */}
      <Grid item xs={12} sm={8} md={4}>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#f9f9f9",
            borderRadius: "20px",
            padding: "16px",
            maxWidth: "100%",
            margin: "auto",
            mt: "10px",
          }}
        >
          <CardContent>
            <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: "10px" }}>
              Comments
            </Typography>
            {renderComments()}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default LeadProfile;

