import React, { useState, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Box,
  useMediaQuery,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import { useAuth } from "../contexts/AuthContext";
import { dataPost } from "../services/ApiEndPoint";
import toast from "react-hot-toast";
//  user dashboard imports  
import UserCustomerDashboard from "../Dashboard/UserDashboard/Customer/UserCustomerDashboard";
import UserLeadsDashboard from "../Dashboard/UserDashboard/Leads/UserLeadsDashboard";
import UserDealsDashboard from "../Dashboard/UserDashboard/Deals/userDealsDashboard";
import UserCompaniesDashboard from "../Dashboard/UserDashboard/Companies/UserCompaniesDashboard";
import UserQuotationsDashboard from "../Dashboard/UserDashboard/Quotations/UserQuotationsDashboard";
import UserMeetingsDashboard from "../Dashboard/UserDashboard/Meetings/UserMeetingsDashboard";
import RnrLeadList from "../Dashboard/UserDashboard/Leads/RnrLeadList";



import {
  GroupAddRounded,
  Group,
  ContactPage,
  AccountCircle,
  MonetizationOnOutlined,
  ContactPageOutlined,
  Work,
  MonetizationOn,
  TaskAltOutlined,
  ScheduleOutlined,
  DocumentScannerOutlined,
  SwitchAccessShortcutOutlined,
  GroupAddSharp,
  DashboardCustomize,
  LocalActivity,
  HourglassFullOutlined,
  HourglassBottomTwoTone,
  HourglassDisabled,
  HolidayVillageSharp,
  HourglassTopOutlined,
} from "@mui/icons-material";
// admin dashboard imports
import CustomerDashboard from "../Dashboard/AdminDashboard/Customer/CustomerDashboard";
import LeadsDashboard from "../Dashboard/AdminDashboard/Leads/LeadsDashboard";
import DealsDashboard from "../Dashboard/AdminDashboard/Deals/DealsDashboard";
import ContactsDashboard from "../Dashboard/AdminDashboard/Contacts/ContactsDashboard";
import CompaniesDashboard from "../Dashboard/AdminDashboard/Companies/CompaniesDashboard";
import QuotationDashboard from "../Dashboard/AdminDashboard/Quotations/QuotationDashboard";
import TasksDashboard from "../Dashboard/AdminDashboard/Tasks/TasksDashboard";
import MeetingsDashboard from "../Dashboard/AdminDashboard/Meetings/MeetingsDashboard";
import ReportsDashboard from "../Dashboard/AdminDashboard/Reports/ReportsDashboard";
import Profile from "../Dashboard/AdminDashboard/User/Profile";
import UserDashboard from "../Dashboard/AdminDashboard/User/UserDashboard/UserDashbaord";
import AccessControlDashboard from "../Dashboard/AdminDashboard/Access-Control/AccessControlDashboard";
import UserTasksDashboard from "../Dashboard/UserDashboard/Tasks/UserTasksDashboard";
import UserReportsDashboard from "../Dashboard/UserDashboard/Reports/UserReportsDashboard";

// manager dashboard imports
import MgrAccessControlDashboard from "../Dashboard/ManagerDashboard/Access-Control/MgrAccessControlDashboard";
import MgrCompaniesDashboard from "../Dashboard/ManagerDashboard/Companies/MgrCompaniesDashboard";
import MgrCustomerDashboard from "../Dashboard/ManagerDashboard/Customer/MgrCustomerDashboard";
import MgrDealsDashboard from "../Dashboard/ManagerDashboard/Deals/MgrDealsDashboard";
import MgrLeadsDashboard from "../Dashboard/ManagerDashboard/Leads/MgrLeadsDashboard";
import MgrQuotationDashboard from "../Dashboard/ManagerDashboard/Quotations/MgrQuotationDashboard";
import MgrTasksDashboard from "../Dashboard/ManagerDashboard/Tasks/MgrTasksDashboard";
import MgrReportsDashboard from "../Dashboard/ManagerDashboard/Reports/MgrReportsDashboard";
import MgrMeetingsDashboard from "../Dashboard/ManagerDashboard/Meetings/MgrMeetingsDashboard";
import MgrUserDashboard from "../Dashboard/ManagerDashboard/User/UserDashboard/MgrUserDashbaord";
import AdminDashboard from "../Dashboard/AdminDashboard/AdminDashboard";
import ManagerDashboard from "../Dashboard/ManagerDashboard/ManagerDashboard";
import Dashboard from "../Dashboard/UserDashboard/Dashboard";
import MgrWorkSessionDashboard from "../Dashboard/ManagerDashboard/Work-Session/MgrWorkSessionDashboard";
import { sub } from "date-fns";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MgrNewLeadList from "../Dashboard/ManagerDashboard/Leads/MgrNewLeadList";
import MgrRnrLeadList from "../Dashboard/ManagerDashboard/Leads/MgrRnrLeadList";
import MgrPostPonedLeadList from "../Dashboard/ManagerDashboard/Leads/MgrContactedLeadList";
import ConvertedLead from "../Dashboard/UserDashboard/Leads/ConvertedLead";
import InterestedLead from "../Dashboard/UserDashboard/Leads/InterestedLead";
import NotInterestedLead from "../Dashboard/UserDashboard/Leads/NotInterestedLead";
import DeadLead from "../Dashboard/UserDashboard/Leads/DeadLead";
import ReOpenedLead from "../Dashboard/UserDashboard/Leads/Reopenedlead";

import MgrInterestedLead from "../Dashboard/ManagerDashboard/Leads/MgrInterestedlead";
import MgrNotInterestedLead from "../Dashboard/ManagerDashboard/Leads/MgrNotInterestedlead";
import MgrDeadLead from "../Dashboard/ManagerDashboard/Leads/MgrDeadLead";
import MgrReOpenedLead from "../Dashboard/ManagerDashboard/Leads/MgrReOpenedLead";
import MgrConvertedLead from "../Dashboard/ManagerDashboard/Leads/MgrConvertedLead";
import ContactedLeadList from "../Dashboard/UserDashboard/Leads/ContactedLeadList";
import MgrContactedLeadList from "../Dashboard/ManagerDashboard/Leads/MgrContactedLeadList";

function Layout() {
  const [themeMode, setThemeMode] = useState("light");
  const { userData, logout } = useAuth();
  const [selectedSection, setSelectedSection] = useState(
    localStorage.getItem("selectedSection") || "Dashboard"
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const drawerWidth = isSmallScreen ? 170 : isMediumScreen ? 200 : 240;
  const currentYear = new Date().getFullYear();
 
 
//   const [lastActivityTime, setLastActivityTime] = useState(Date.now());

//   const resetInactivityTimer = () => {
//     setLastActivityTime(Date.now());
//   };

 
// // Logout after 10 minutes of inactivity
// useEffect(() => {
//   const timeout = 10 * 60 * 1000; // 10 minutes in milliseconds
//   const checkInactivity = setInterval(() => {
//     if (Date.now() - lastActivityTime >= timeout) {
//       handleLogout(); // Automatically logout if inactive for 15 minutes
//     }
//   }, 1000); // Check every second

//   return () => clearInterval(checkInactivity);
// }, [lastActivityTime]);

// // Listen for user activity (e.g., mouse movements, key presses)
// useEffect(() => {
//   const handleUserActivity = () => {
//     resetInactivityTimer();
//   };

//   window.addEventListener("mousemove", handleUserActivity);
//   window.addEventListener("keypress", handleUserActivity);

//   // Clean up event listeners when the component is unmounted
//   return () => {
//     window.removeEventListener("mousemove", handleUserActivity);
//     window.removeEventListener("keypress", handleUserActivity);
//   };
// }, []);

useEffect(() => {
  const savedTheme = localStorage.getItem("theme") || "light";
  setThemeMode(savedTheme);
}, []);
  
  const handleSectionChange = (section) => {
    setSelectedSection(section);
    localStorage.setItem("selectedSection", section); // Save to localStorage
  }

  const allMenuItems = {
    admin: [
      { name: "Dashboard", icon: <DashboardCustomize /> },
      {
        name: "Leads",
        icon: <ContactPage />,
        subMenu: [
          { name: "Assign", icon: <AddCircleIcon /> },
          { name: "New", icon: <AddCircleIcon/> },
          { name: "RNR", icon: <CheckCircleIcon /> },
          { name: "Contacted", icon: <HourglassEmptyIcon /> },
          {name:"Converted",icon:<HourglassFullOutlined/>},
          {name:"Interested",icon:<HourglassBottomTwoTone/>},
          {name:"Not Interested",icon:<HourglassDisabled/>},
          {name:"Dead",icon:<HolidayVillageSharp/>},
          {name:"ReOpened",icon:<HourglassTopOutlined/>}
          
        ],
      },
      { name: "Deals", icon: <MonetizationOnOutlined /> },
      { name: "Customers", icon: <Group /> },
      { name: "Contacts", icon: <ContactPageOutlined /> },
      { name: "Companies", icon: <Work /> },
      { name: "Quotations", icon: <MonetizationOn /> },
      { name: "Tasks", icon: <TaskAltOutlined /> },
      { name: "Meetings", icon: <ScheduleOutlined /> },
      { name: "Reports", icon: <DocumentScannerOutlined /> },
      { name: "ActivityLog", icon: <LocalActivity /> },
      { name: "Users", icon: <GroupAddSharp /> },
      { name: "Access Control", icon: <SwitchAccessShortcutOutlined /> },
      { name: "Profile", icon: <AccountCircle /> },
    ],
    manager: [
      { name: "Dashboard", icon: <DashboardCustomize /> },
      {
        name: "Leads",
        icon: <ContactPage />,
        subMenu: [
          { name: "Assign", icon: <AddCircleIcon /> },
          { name: "New", icon: <AddCircleIcon /> },
          { name: "RNR", icon: <CheckCircleIcon/> },
          { name: "Contacted", icon: <HourglassEmptyIcon /> },
          {name:"Converted",icon:<HourglassFullOutlined/>},
          {name:"Interested",icon:<HourglassBottomTwoTone/>},
          {name:"Not Interested",icon:<HourglassDisabled/>},
          {name:"Dead",icon:<HolidayVillageSharp/>},
          {name:"ReOpened",icon:<HourglassTopOutlined/>}
        ],
      },
      { name: "Deals", icon: <MonetizationOnOutlined /> },
      { name: "Customers", icon: <Group /> },
      { name: "Contacts", icon: <ContactPageOutlined /> },
      { name: "Companies", icon: <Work /> },
      { name: "Quotations", icon: <MonetizationOn /> },
      { name: "Tasks", icon: <TaskAltOutlined /> },
      { name: "Meetings", icon: <ScheduleOutlined /> },
      { name: "Reports", icon: <DocumentScannerOutlined /> },
      { name: "ActivityLog", icon: <LocalActivity /> },
      { name: "Users", icon: <GroupAddSharp /> },
      { name: "Access Control", icon: <SwitchAccessShortcutOutlined /> },
      { name: "Profile", icon: <AccountCircle /> },
    ],
    user: [
      { name: "Dashboard", icon: <DashboardCustomize /> },
      {
        name: "Leads",
        icon: <ContactPage />,
        subMenu: [
          { name: "New", icon: <AddCircleIcon /> },
          { name: "RNR", icon: <CheckCircleIcon/> },
          { name: "Contacted", icon: <HourglassEmptyIcon /> },
          {name:"Converted",icon:<HourglassFullOutlined/>},
          {name:"Interested",icon:<HourglassBottomTwoTone/>},
          {name:"Not Interested",icon:<HourglassDisabled/>},
          {name:"Dead",icon:<HolidayVillageSharp/>},
          {name:"ReOpened",icon:<HourglassTopOutlined/>}
        ],
      },
      { name: "Deals", icon: <MonetizationOnOutlined /> },
      { name: "Customers", icon: <Group /> },
      { name: "Contacts", icon: <ContactPageOutlined /> },
      { name: "Companies", icon: <Work /> },
      { name: "Quotations", icon: <MonetizationOn /> },
      { name: "Tasks", icon: <TaskAltOutlined /> },
      { name: "Reports", icon: <DocumentScannerOutlined /> },
      { name: "ActivityLog", icon: <LocalActivity /> },
      { name: "Meetings", icon: <ScheduleOutlined /> },
      { name: "Profile", icon: <AccountCircle /> },
    ],
  };
  

  const renderContent = () => {
    const userRole = userData?.role;
  
    const contentMap = {
      admin: {
        Dashboard: <AdminDashboard />,
        "Assign": <MgrLeadsDashboard />,
        "New": <MgrNewLeadList/>,
        "RNR": <MgrRnrLeadList />,
        "Contacted": <MgrContactedLeadList/>,
        "Converted":<MgrConvertedLead/>,
        "Interested":<MgrInterestedLead/>,
        "Not Interested":<MgrNotInterestedLead/>,
        "Dead":<MgrDeadLead/>,
        "ReOpened":<MgrReOpenedLead/>,
        Deals: <DealsDashboard />,
        Customers: <CustomerDashboard />,
        Contacts: <ContactsDashboard />,
        Companies: <CompaniesDashboard />,
        Quotations: <QuotationDashboard />,
        Tasks: <TasksDashboard />,
        Meetings: <MeetingsDashboard />,
        Reports: <ReportsDashboard />,
        ActivityLog: <MgrWorkSessionDashboard />,
        Users: <UserDashboard />,
        "Access Control": <AccessControlDashboard />,
        Profile: <Profile />,
      },
      manager: {
        Dashboard: <ManagerDashboard />,
        "Assign": <MgrLeadsDashboard />,
        "New": <MgrNewLeadList/>,
        "RNR": <MgrRnrLeadList />,
        "Contacted": <MgrContactedLeadList/>, 
         "Converted":<MgrConvertedLead/>,
        "Interested":<MgrInterestedLead/>,
        "Not Interested":<MgrNotInterestedLead/>,
        "Dead":<MgrDeadLead/>,
        "ReOpened":<MgrReOpenedLead/>,
        Deals: <MgrDealsDashboard />,
        Customers: <MgrCustomerDashboard />,
        Contacts: <ContactsDashboard />,
        Companies: <MgrCompaniesDashboard />,
        Quotations: <MgrQuotationDashboard />,
        Tasks: <MgrTasksDashboard />,
        Meetings: <MgrMeetingsDashboard />,
        Reports: <MgrReportsDashboard />,
        ActivityLog: <MgrWorkSessionDashboard />,
        Users: <MgrUserDashboard />,
        "Access Control": <MgrAccessControlDashboard />,
        Profile: <Profile />,
      },
      user: {
        Dashboard: <Dashboard />,
        "New": <UserLeadsDashboard />,
        "RNR": <RnrLeadList />,
        "Contacted": <ContactedLeadList/>,
        "Converted":<ConvertedLead/>,
        "Interested":<InterestedLead/>,
        "Not Interested":<NotInterestedLead/>,
        "Dead":<DeadLead/>,
        "ReOpened":<ReOpenedLead/>,

        
        Deals: <UserDealsDashboard />,
        Customers: <UserCustomerDashboard />,
        Contacts: <ContactsDashboard />,
        Companies: <UserCompaniesDashboard />,
        Quotations: <UserQuotationsDashboard />,
        Tasks: <UserTasksDashboard />,
        Reports: <UserReportsDashboard />,
        Meetings: <UserMeetingsDashboard />,
        ActivityLog: <MgrWorkSessionDashboard />,
        Profile: <Profile />,
      },
    };
  
  return contentMap[userRole]?.[selectedSection] || <Dashboard />;
  };
  
  

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      background: { default: "#ffffff", paper: "#ffffff" },
      text: { primary: "#000000" },
      primary: { main: "#1976d2" },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: { default: "#121212", paper: "#1d1d1d" },
      text: { primary: "#ffffff" },
      primary: { main: "#bb86fc" },
    },
  });

  const handleThemeChange = () => {
    setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };



  
  const handleLogout = async () => {
    try {
      const response = await dataPost("auth/logout",
        {
          email: userData.email,
        }
      );
      if (response.status === 200) {
        localStorage.clear();
        toast.success(response.data.message);
        logout();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const logo =()=>{
    return <Tooltip title="Sales Tech INC" placement="right">
    <IconButton>
      <img
        src="https://salestechinc.com/salestech2.png"
        alt="Company Logo"
        style={{ width: 60, height: 50, borderRadius: "10%" }}
      />
    </IconButton>
  </Tooltip>
  }
  

  return (
    <ThemeProvider theme={themeMode === "light" ? lightTheme : darkTheme}>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Sidebar
          onSectionChange={handleSectionChange}
          drawerWidth={drawerWidth}
          selectedSection={selectedSection}
          userRole={userData?.role}
          userImage={userData?.img || "https://img.freepik.com/free-photo/portrait-happy-manager-holding-leather-case_1262-5329.jpg"}
          menuItems={allMenuItems[userData?.role]}
          logo={
            logo()}
        />
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <Topbar
            userName={userData?.name || "User"}
            userEmail={userData?.email || "Email"}
            userRole={userData?.role || "Role"}
            onThemeChange={handleThemeChange}
            onLogout={handleLogout}
            onSectionChange={handleSectionChange}
            drawerWidth={drawerWidth}
            selectedSection={selectedSection}
            menuItems={allMenuItems[userData?.role]}
            userImage={userData?.img || "https://img.freepik.com/free-photo/portrait-happy-manager-holding-leather-case_1262-5329.jpg"}
            logo={
             logo()}
          />
          <Box component="main" sx={{ flexGrow: 1, p: 3,mt:1 }}>
            {renderContent()}
          </Box>

          <Box component = "footer" sx={{display:"flex",justifyContent:"center",alignItems:"center",height:50,backgroundColor:"grey",color:"black"}}>
            <p>Powered by</p> 
            <p> © {currentYear} Sales Tech INC</p>


              </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Layout;
