import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { dataFetch, dataUpdate } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";

const EditLeadModal = ({ onClose, open, lead = {}, onUpdate }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const defaultDate = new Date().toISOString().substring(0, 10);

  const initialValues = {
    firstname: lead?.firstname || "",
    fullname: lead?.fullname || "",
    leadowner: lead?.leadowner?._id || "",
    assignedLeads: lead?.assignedLeads.map(user => user._id) || [],  // Default to existing assigned leads
    location: lead?.location || "",
    date: lead?.date ? lead.date.substring(0, 10) : defaultDate,
    pipelinestage: lead?.pipelinestage || "",
    companyname: lead?.companyname || "",
    phonenumber: lead?.phonenumber || "",
    email: lead?.email || "",
    timezone: lead?.timezone || "",
    pipelineComments: lead?.pipelineComments ? lead.pipelineComments.map(c => c.pipelineComment).join(", ") : "",
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        setUsers(Array.isArray(response.data.data) ? response.data.data : []);
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to fetch users");
      }
    };

    fetchUsers();
  }, [token]);

  const validationSchema = Yup.object({
    firstname: Yup.string(),
    fullname: Yup.string(),
    leadowner: Yup.string(),
    pipelinestage: Yup.string(),
    companyname: Yup.string(),
    phonenumber: Yup.string().required("Phone number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    timezone: Yup.string(),
    pipelineComments: Yup.string(),
    location: Yup.string(),
    date: Yup.string().required("Date is required"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const updatedValues = {
        ...values,
        pipelineComments: values.pipelineComments
          ? values.pipelineComments.split(",").map(comment => ({
              pipelineComment: comment.trim(),
              pipelineDate: new Date(),
            }))
          : [],
      };

      const response = await dataUpdate(`lead/${lead._id}`, updatedValues, token);
      if (response.status === 200) {
        toast.success("Lead updated successfully");
        onUpdate(updatedValues);
        resetForm();
        onClose();
      } else {
        console.error("Failed to update lead:", response.data);
        toast.error("Failed to update lead");
      }
    } catch (error) {
      console.error("Error updating lead:", error);
      toast.error("Error updating lead");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Lead</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ touched, errors, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="firstname"
                    as={TextField}
                    label="First Name"
                    fullWidth
                    error={touched.firstname && Boolean(errors.firstname)}
                    helperText={touched.firstname && errors.firstname}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="fullname"
                    as={TextField}
                    label="Full Name"
                    fullWidth
                    error={touched.fullname && Boolean(errors.fullname)}
                    helperText={touched.fullname && errors.fullname}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="leadowner"
                    as={TextField}
                    select
                    label="Lead Owner"
                    fullWidth
                    error={touched.leadowner && Boolean(errors.leadowner)}
                    helperText={touched.leadowner && errors.leadowner}
                  >
                    {users.length > 0 ? (
                      users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No users available</MenuItem>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="assignedLeads"
                    as={TextField}
                    select
                    label="Assigned Leads"
                    fullWidth
                    error={touched.assignedLeads && Boolean(errors.assignedLeads)}
                    helperText={touched.assignedLeads && errors.assignedLeads}
                    multiple
                  >
                    {users.length > 0 ? (
                      users.map((assigned) => (
                        <MenuItem key={assigned._id} value={assigned._id}>
                          {assigned.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No assigned leads available</MenuItem>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="date"
                    as={TextField}
                    label="Date"
                    type="date"
                    fullWidth
                    error={touched.date && Boolean(errors.date)}
                    helperText={touched.date && errors.date}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="location"
                    as={TextField}
                    label="Location"
                    fullWidth
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="pipelinestage"
                    as={TextField}
                    select
                    label="Pipeline Stage"
                    fullWidth
                    error={touched.pipelinestage && Boolean(errors.pipelinestage)}
                    helperText={touched.pipelinestage && errors.pipelinestage}
                  >
                    {["New", "Inactive", "Converted", "Contacted", "Reopened", "Interested", "NotInterested", "Dead"].map((stage) => (
                      <MenuItem key={stage} value={stage}>
                        {stage}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="companyname"
                    as={TextField}
                    label="Company Name"
                    fullWidth
                    error={touched.companyname && Boolean(errors.companyname)}
                    helperText={touched.companyname && errors.companyname}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="phonenumber"
                    as={TextField}
                    label="Phone Number"
                    fullWidth
                    error={touched.phonenumber && Boolean(errors.phonenumber)}
                    helperText={touched.phonenumber && errors.phonenumber}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="pipelineComments"
                    as={TextField}
                    label="Pipeline Comments"
                    fullWidth
                    multiline
                    rows={3}
                    error={touched.pipelineComments && Boolean(errors.pipelineComments)}
                    helperText={touched.pipelineComments && errors.pipelineComments}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary">Update Lead</Button>
              <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditLeadModal;
